import { createStyles } from "@mantine/emotion";

export default createStyles ( () => ( {
	statContainer: {
		position : "absolute",
		left     : "10px",
		top      : "0",
		zIndex   : 50
	},
	sessionStatsContainer: {
		display    : "flex",
		alignItems : "center"
	},
	liveTagText: {
		fontWeight : "500",
		color      : "#fff",
		lineHeight : "1.5"
	},
	eyeIcon: {
		height : "15px",
		width  : "15px"
	}
} ) );