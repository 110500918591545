import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	liveTagContainer: {
		backgroundColor : `${ theme.other.paletteNew.tertiaryPink } !important`,
		padding         : "0.1rem 0.5rem",
		borderRadius    : "4px",
		display         : "flex",
		margin          : "1rem 0 1rem 1rem",
		alignItems      : "flex-start"
	},
	liveTagText: {
		fontWeight : "500",
		color      : "#fff",
		fontSize   : "1.2rem"
	},
	svgIcon: {
		height      : "1.4rem",
		width       : "1rem",
		marginRight : "0.5rem"
	}
} ) );
