import { shortenNumber } from "_common/utils";
import Typography        from "_components/atoms/typography";
import useStyles         from "_styles/atoms/liveCount";

export default function LiveCount ( { liveCount } ) {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div className = { classes.liveTagContainer }>
			{/* {isGiveawaySession ? (
				<Icon sameInBothTheme
					className = { classes.svgIcon }
					name = "giveawaysIconWhite"
				/>
			) : null} */}

			<Typography
				className = { classes.liveTagText }
				title     = { liveCount ? `${ shortenNumber ( liveCount ) }` : "" + "LIVE" }
			/>
		</div>
	);
}
