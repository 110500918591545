import { createStyles } from "@mantine/emotion";

export default createStyles ( () => ( {
	viewTagContainer: {
		background   : "rgb(0,0,0,0.5)",
		padding      : "0.1rem 0.5rem",
		borderRadius : "4px",
		display      : "flex",
		margin       : "1rem 0 1rem 0.5rem",
		alignItems   : "center"
	},
	viewTagText: {
		// lineHeight : "1.5",
		fontWeight : "500",
		color      : "#fff",
		fontSize   : "1.2rem",
		marginLeft : "0.3rem"

	},
	eyeIcon: {
		height : "1rem",
		width  : "1rem"
	}
} ) );