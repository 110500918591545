import dynamic      from "next/dynamic";
import { useState } from "react";
import { Waypoint } from "react-waypoint";

import Icon                          from "_components/atoms/icon";
import Image                         from "_components/atoms/image";
import Render                        from "_components/atoms/render";
import Typography                    from "_components/atoms/typography";
import LiveSessionStats              from "_components/molecules/liveSessionStats";
import { useGetCanWatchMoreContent } from "_jotai/compulsoryLogin";

import useStyles from "./styles";

const VideoPlayer = dynamic ( () => import ( "_components/features/player" ) );

const component = ( {
	pip,
	isActive,
	data,
	onClickAvatar,
	onClickCard,
	muted,
	toggleMute,
	hoverVideo
} ) => {

	const { classes }           = useStyles ( { isActive } );
	const [ inView, setInView ] = useState ( true );
	const canWatchMoreContent   = useGetCanWatchMoreContent ();

	return (
		<div className = { classes.container }
			onClick       = { onClickCard }
		>
			<Waypoint
				onEnter = { () => {
					setInView ( true );
				} }
				onLeave = { () => {
					setInView ( false );
				} }
			>
				<div>
					{
						isActive && inView && canWatchMoreContent && !pip && !hoverVideo ? (
							<VideoPlayer
								fromVideoCard
								customControls     = { () => (
									<CustomControls
										canWatchMoreContent = { canWatchMoreContent }
										muted               = { muted }
										toggleMute          = { toggleMute }
									/>
								) }
								muted              = { muted }
								overridePlayerMute = { muted }
								playerCss          = { { height: "100%" } }
								src                = { data.session.cdnUrl }
								thumbnail          = { data.session.thumbnail }
							/>
						) : (
							<>
								<Render condition = { !canWatchMoreContent && isActive }>
									<div className = { classes.customControlsWrapper }>
										<Typography
											className = { classes.loginCTA }
											title     = "Login to Play"
										/>
									</div>
								</Render>

								<Image
									className = { classes.thumbnail }
									src       = { data.session.thumbnail }
								/>
							</>
						)
					}
				</div>
			</Waypoint>

			<div className = { classes.carouselOverlay } />

			<div className = { classes.detailsContainer }>
				<Image className = { classes.avatar }
					onClick         = { onClickAvatar }
					src             = { data.actorImage }
					type            = "mdpi"
				/>

				<div className = { classes.userInfoContainer }>
					<Typography
						className = { classes.title }
						lineClamp = { 1 }
						title     = { data.session.sessionInfo }
					/>

					<div className = "flex alignItemsCenter">
						<Typography
							className = { classes.info }
							onClick   = { onClickAvatar }
							size      = "sm"
							title     = { `${ data.actorName }` }
						/>

						<Render condition = { data?.isCeleb }>
							<Icon sameInBothTheme
								className = { classes.verifyIcon }
								name      = "verifiedTickGrey"
								size      = { 20 }
							/>

						</Render>

						<div className = { classes.ellipse } />

						<Typography
							className = { classes.info }
							size      = "sm"
							title     = { `${ data.timeElapsed }` }
						/>
					</div>

				</div>
			</div>

			<LiveSessionStats
				classnames        = "leftPositioned topPositioned zIndexOne"
				isGiveawaySession = { data.isLeaderBoardLive }
				viewCount         = { data.session.liveViews }
			/>
		</div>
	);
};

export default component;

function CustomControls ( {
	muted,
	toggleMute,
	canWatchMoreContent
} ) {
	const { classes } = useStyles ();

	if ( canWatchMoreContent )
		return (
			<div className = { classes.customControlsWrapper }>
				<Icon
					sameInBothTheme
					className = { classes.muteIcon }
					name      = { muted ? "muteCarousel" : "unmuteCarousel" }
					onClick   = { e => {
						e.stopPropagation ();
						toggleMute ( !muted );
					} }
				/>
			</div>
		);

	return (
		<div className = { classes.customControlsWrapper }>
			<Typography
				className = { classes.loginCTA }
				title     = "Login to Play"
			/>
		</div>
	);

}
