import Presentation from "./presentation";

const Carousel = ( { data } ) => {
	return (
		<Presentation
			data = { data }
		/>
	);
};

export default Carousel;
