import { useRouter } from "next/router";

import { storeEvent }  from "_analytics/index";
import {
	useGetCardMuteState,
	useGetHoverAnimation,
	useGetPipModeStatus,
	useSetCardMuteState
} from "_common/hooks/player";

import Presentation from "./presentation";

const Carousel = ( {
	isActive,
	data,
	index,
	currentSlide,
	lastIndex,
	scrollNext,
	scrollPrev,
	from
} ) => {
	const router = useRouter ();

	const hoverVideoPlayed = useGetHoverAnimation ();
	const pipStatus        = useGetPipModeStatus ();
	const muted            = useGetCardMuteState ();
	const toggleMute       = useSetCardMuteState ();

	const onClickAvatar = e => {
		e.stopPropagation ();
		router.push ( `/profile/${ data.session.broadcaster.sportsFan.id }` );
	};

	const handleGAEvent = label => {
		storeEvent ( "Home_carousel", "Swiped", label );
	};

	const onClickCard = ( { index } ) => {

		if ( !isActive ) {
			if ( currentSlide === 0 && index === lastIndex ) {
				scrollPrev ();
				handleGAEvent ( "left_swipe" );

				return;
			}
			if ( currentSlide === lastIndex && index === 0 ) {
				scrollNext ();
				handleGAEvent ( "right_swipe" );

				return;
			}

			if ( index > currentSlide ) {
				scrollNext ();
				handleGAEvent ( "right_swipe" );
			} else {
				scrollPrev ();
				handleGAEvent ( "left_swipe" );
			}

			return;
		}

		router.push ( `/stream/${ data.session.id }` );
	};

	return (
		<Presentation
			data          = { data }
			hoverVideo    = { hoverVideoPlayed }
			isActive      = { isActive }
			muted         = { muted }
			onClickAvatar = { onClickAvatar }
			onClickCard   = { () => onClickCard ( { index } ) }
			pip           = { pipStatus }
			toggleMute    = { toggleMute }
		/>
	);
};

export default Carousel;
