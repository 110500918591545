import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( () => ( {
	cardContainer: {
		height: "100%"
	},
	carouselContainer: {
		margin: "2rem 0",

		[rooterMediaQuery.mobile]: {
			margin: "1rem 0"
		}
	},
	control: {
		opacity: 1
	},
	controlIcon: {
		height : "4rem",
		width  : "4rem"
	},
	controls: {
		top       : "50%",
		transform : "translate(0, -50%)",

		[rooterMediaQuery.mobile]: {
			paddingLeft  : "0",
			paddingRight : "0"
		},

		[rooterMediaQuery.tablet]: {
			paddingLeft  : "28%",
			paddingRight : "28%"
		},

		[rooterMediaQuery.desktop]: {
			paddingLeft  : "31%",
			paddingRight : "31%"
		}
	}
} ) );