import LiveCount from "_components/atoms/liveCount";
import ViewCount from "_components/atoms/viewCount";
import useStyles from "_styles/molecules/liveSessionStats";

export default function LiveSessionStats ( { viewCount, liveCount, classnames = "", disableLiveTag, isGiveawaySession } ) {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	// return null;

	return (
		<div className = { `absolutePositioned ${ classnames }` }>
			<div className = { classes.sessionStatsContainer }>
				{!disableLiveTag && (
					<div className = { classes.sessionStatsContainer }>
						<LiveCount
							isGiveawaySession = { isGiveawaySession }
							liveCount         = { liveCount }
						/>
					</div>
				)}

				<div>

					{viewCount ? <ViewCount viewCount = { viewCount } /> : null}
				</div>
			</div>

		</div>
	);
}
