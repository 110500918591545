import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		container: {
			aspectRatio  : "16/9",
			height       : "100%",
			borderRadius : "1.5rem",
			position     : "relative",
			overflow     : "hidden",
			cursor       : "pointer",

			[rooterMediaQuery.mobile]: {
				borderRadius: "1rem"
			}
		},
		detailsContainer: {
			position        : "absolute",
			bottom          : "0%",
			width           : "100%",
			display         : "flex",
			alignItems      : "center",
			backgroundImage : "linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)",
			padding         : "1rem"

		},
		avatar: {
			height       : "4.8rem",
			width        : "4.8rem",
			borderRadius : "100%",
			marginRight  : "1rem"
		},
		title: {
			fontWeight : "500",
			color      : theme.other.paletteNew.darkModeWhite80
		},
		info: {
			color: theme.other.paletteNew.darkModeWhite80
		},
		customControlsWrapper: {
			cursor   : "pointer",
			position : "absolute",
			top      : "4%",
			right    : "2%",
			zIndex   : "100"
		},
		loginCTA: {
			background   : theme.other.paletteNew.secondaryBlack,
			padding      : "0.4rem 0.8rem",
			borderRadius : "0.6rem",
			fontSize     : "1.2rem",
			color        : theme.other.paletteNew.darkModeWhite80
		},
		muteIcon: {
			width: "2.8rem"
		},
		carouselOverlay: {
			height          : "100%",
			width           : "100%",
			backgroundImage : !( props?.isActive ) ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))" : "none",
			top             : "0%",
			right           : "0%",
			position        : "absolute"
		},
		thumbnail: {
			height         : "100%",
			width          : "100%",
			backgroundSize : "cover",
			borderRadius   : "1.5rem"
		},
		ellipse: {
			// color           : theme.other.paletteNew.darkModeWhite80,
			width           : "4px",
			height          : "4px",
			margin          : "0rem 0.7rem",
			borderRadius    : "50%",
			backgroundColor : "#fff"
		},
		verifyIcon: {
			padding: "3px"
		}
	};
} );

